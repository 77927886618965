import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
/* import {SiteMessagesService} from '../services/site-messages.service'; */
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessagingService } from '../services/messaging.service';
/* import {AngularFireDatabase} from 'angularfire2/database'; */

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  newmessage: any;
  messages;
  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessagingService,
/*   af: AngularFireDatabase ,  */

    private router: Router
  ) {
      this.messages = /* af.list('/messages');  */
    this.newmessage = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required]

    });

/*     console.log(this.newmessage); */
  }
  ngOnInit() {

 }/*
  onSubmit({ value, valid }: { value, valid: boolean }) {
     this.fruitservice.addFruit(value.name);
    console.log(value, valid);
  }*/


  onSubmit(message) {
     this.messageService.addMessage(message.value);
     alert('Thanks we\'ll be in touch');
     this.router.navigate(['/']);
  }
}
