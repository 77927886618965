import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { EquipmentComponent } from './equipment/equipment.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'equipment', component: EquipmentComponent },
  { path: 'jukeboxes', loadChildren: () => import('./jukeboxes/jukeboxes.module').then(m => m.JukeboxesModule)},
  { path: 'pool', loadChildren: () => import('./pool/pool.module').then(m => m.PoolModule)},
  { path: 'poker', loadChildren: () => import('./poker/poker.module').then(m => m.PokerModule)},
  { path: 'fruits', loadChildren: () => import('./fruits/fruits.module').then(m => m.FruitsModule)},
  { path: 'casino', loadChildren: () => import('./casino/casino.module').then(m => m.CasinoModule)},
  { path: 'retro', loadChildren: () => import('./retro/retro.module').then(m => m.RetroModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
