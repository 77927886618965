import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AngularFirestore,  AngularFirestoreDocument,  AngularFirestoreCollection} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  itemsRef: AngularFirestoreCollection<any>;
  items: Observable<any[]>;
  fruitDoc;
  constructor(private db: AngularFirestore) {
    this.itemsRef = db.collection('Messages', ref => ref.orderBy('email', 'asc'));
    // Use snapshotChanges().map() to store the key
    this.items = this.itemsRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getFruits(): Observable<any[]> {
    return this.items;
  }
getFruit(id: string): Observable<any> {
  return this.db.doc<any>('Messages/' + id).snapshotChanges()
  .pipe(
    map(action => {
      const data = action.payload.data();
      id = action.payload.id;
      return { id, ...data };
    }));
}


addMessage(message) {
  /* this.db.collection('Fruits').doc('{{fruit.id}}').set({'name': '{{fruit.name}}', 'content': '{{fruit.name}}'}); */
  this.db.collection('Messages').add(message);
}


}
